<template>
  <base-material-card class="px-5 py-3" color="primary">
    <template v-slot:heading>
      <v-row class="ma-auto">
        <v-col md="8" sm="8">
          <h3 v-if="tabId==0">{{$t('tables.all-files')}}
            <v-badge offset-x="-5" offset-y="-5" color="red"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==1">{{$t('tables.originals')}}
            <v-badge offset-x="-5" offset-y="-5" color="indigo"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==2">{{$t('tables.translated')}}
            <v-badge offset-x="-5" offset-y="-5" color="green darken-3"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==3">{{$t('tables.reference')}}
            <v-badge offset-x="-5" offset-y="-5" color="orange"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==4">{{$t('tables.tm')}}
            <v-badge offset-x="-5" offset-y="-5" color="deep-purple"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==5">{{$t('tables.glossary')}}
            <v-badge offset-x="-5" offset-y="-5" color="amber"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==6">{{$t('tables.finance')}}
            <v-badge offset-x="-5" offset-y="-5" color="cyan"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
          <h3 v-if="tabId==7">{{$t('tables.other')}}
            <v-badge offset-x="-5" offset-y="-5" color="indigo darken-4"
                     :content="files.length?files.length:'0'"></v-badge>
          </h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="ma-auto">
      </v-row>
    </template>

    <v-card-title>
      <v-row class="ma-auto">
        <v-col cols="12" md="4" sm="12">
          <v-text-field v-model="search" append-icon="mdi-magnify"
                        :label="$t('search')" single-line hide-details>
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>

    <v-data-table
      dense
      single-expand
      item-key="fileId"
      loader-height="5"
      :loading="loading"
      :loading-text="$t('loadings.loading-files')"
      :no-data-text="$t('loadings.no-files-found')"
      multi-sort
      :headers="headers"
      :items="files"
      :search="search"
      show-expand
      :expanded.sync="expanded"
      :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]}">
      <template v-slot:item.order.orderCode="{ item }">
        <router-link :to="{ path: '/order/' + item.order.orderId }" class="no-underline-link">
          {{item.order.orderCode}}
        </router-link>
      </template>
      <template v-slot:item.fileName="{ item }">
        <router-link :to="{ path: '/file/' + item.fileId}" class="no-underline-link">
          {{item.fileName}}
        </router-link>
      </template>
      <template v-slot:item.fileSize="{ item }">
        {{(item.fileSize/1024).toFixed(0)}} Kb
      </template>
      <template v-slot:item.fileExtension="{ item }">
        <v-chip :color="getExtensionColor(item.fileExtension)" dark small label
                style="width: 75px;justify-content: center">
          {{ item.fileExtension }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dense>
            <tr>
              <td>
                {{$t('table-expand.created-date')}}:
              </td>
              <td>
                <h5>{{ parseTime(item.createdTime) }}</h5>
              </td>
              <td>
                {{$t('table-expand.modified-date')}}:
              </td>
              <td>
                <h5>{{ parseTime(item.modifiedTime) }}</h5>
              </td>
            </tr>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
  import CreateObjectButton from "../../components/buttons/CreateObjectButton";

  let moment = require('moment');

  export default {
    props: ['tabId', 'files', 'loading'],
    name: "Files",
    components: {CreateObjectButton},
    data() {
      return {
        object: 'File',
        expanded: [],
        search: '',
        headers: [
          {
            text: this.$t('tables.order-code'),
            align: 'start',
            sortable: false,
            value: 'order.orderCode',
            class: "primary--text"
          },
          {text: this.$t('tables.fileName'), value: 'fileName', class: "primary--text", sortable: true},
          {text: this.$t('tables.fileSize'), value: 'fileSize', class: "primary--text", sortable: true},
          {text: this.$t('tables.fileType'), value: 'fileType', class: "primary--text", sortable: true},
          {text: this.$t('tables.fileExtension'), value: 'fileExtension', class: "primary--text", sortable: true},
        ],
      }
    },
    methods: {
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
      parseDate(date) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(date).format("DD.MM.YYYY");
      },
      getExtensionColor(ext) {
        let color = 'blue-grey lighten-1';
        if (ext === 'doc' || ext === 'docx' || ext === 'DOC' || ext === 'DOCX') {
          color = 'blue'
        }
        if (ext === 'pdf' || ext === 'PDF') {
          color = 'red'
        }
        if (ext === 'csv' || ext === 'txt' || ext === 'CSV' || ext === 'TXT') {
          color = 'cyan lighten-4'
        }
        if (ext === 'xls' || ext === 'xlsx' || ext === 'XLS' || ext === 'XLSX') {
          color = 'green darken-3'
        }
        if (ext === 'ppt' || ext === 'pptx' || ext === 'PPT' || ext === 'PPTX') {
          color = 'orange darken-3'
        }
        if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'bmp' || ext === 'JPG' || ext === 'JPEG' || ext === 'PNG' || ext === 'BMP') {
          color = 'amber darken-1'
        }
        if (ext === 'zip' || ext === 'rar' || ext === '7z' || ext === 'ZIP' || ext === 'RAR' || ext === '7Z') {
          color = 'yellow accent-3'
        }
        if (ext === 'tif' || ext === 'tiff' || ext === 'psd' || ext === 'TIF' || ext === 'TIFF' || ext === 'PSD') {
          color = 'light-blue darken-4'
        }
        return color;
      },
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
