<template>
  <Loader type="files" v-if="loading"></Loader>
  <v-container v-else-if="!loading" fluid class="no-padding-container">
    <v-tabs grow show-arrows continuous slider-size="4" icons-and-text>
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-on:change="filterFiles(0)">
        {{$t('file-tabs.all-files-tab')}}
        <v-icon color="red">mdi-file-document-multiple-outline</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(1,'Original')">
        {{$t('file-tabs.originals-tab')}}
        <v-icon color="indigo">mdi-file-document-edit-outline</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(2,'Translated')">
        {{$t('file-tabs.translated-tab')}}
        <v-icon color="green">mdi-text-box-check-outline</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(3,'Reference')">
        {{$t('file-tabs.reference-tab')}}
        <v-icon color="orange">mdi-help-circle-outline</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(4,'TM')">
        {{$t('file-tabs.tm-tab')}}
        <v-icon color="deep-purple">mdi-brain</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(5,'Glossary')">
        {{$t('file-tabs.glossary-tab')}}
        <v-icon color="amber">mdi-format-list-group</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(6,'Finance')">
        {{$t('file-tabs.finance-tab')}}
        <v-icon color="cyan">mdi-receipt-text-check-outline</v-icon>
      </v-tab>
      <v-tab v-on:change="filterFiles(7,'Other')">
        {{$t('file-tabs.other-tab')}}
        <v-icon color="indigo darken-4">mdi-folder-question-outline</v-icon>
      </v-tab>

      <v-tab-item>
        <Files tabId=0 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=1 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=2 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=3 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=4 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=5 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=6 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

      <v-tab-item>
        <Files tabId=7 :files="filteredFiles" :loading="loading"></Files>
      </v-tab-item>

    </v-tabs>
  </v-container>
</template>

<script>
  import Files from "./Files";
  import Loader from "../../components/Loaders/Loader";
  import {useFiles} from "../../stores/filesStore";
  import {useOrders} from "../../stores/ordersStore";
  import {mapState} from "pinia";

  const filesStore = useFiles()

  export default {
    name: "Files Page",
    components: {Files, Loader},
    data() {
      return {
        filteredFiles: [],
        loading: false
      }
    },
    methods: {
      async updateNeededCheck() {
        if (!this.files.length) {
          this.loading = true;
          let response = await filesStore.getFilesAsync()
          this.files.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : 1);
          this.filterFiles(0)
          this.loading = false;
        }
        if (this.files.length) {
          this.filterFiles(0)
        }
      },
      filterFiles(tabId, type) {
        if (tabId < 1) {
          this.filteredFiles = this.files;
        }
        if (tabId >= 1) {
          this.filteredFiles = this.files.filter(e => e.fileType === type)
        }
        this.filteredFiles.sort((a, b) => (a.order.orderDate > b.order.orderDate) ? -1 : 1);
      },
    },
    computed: {
      ...mapState(useOrders, ['orders']),
      ...mapState(useFiles, ['files']),
    },
    created() {
      this.updateNeededCheck();
    },
  }
</script>

<style>
  .no-padding-container {
    padding: 0;
  }
</style>
